import { List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { mdiFilePdf } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { escala_gris } from '../../utils/constants';
import { useGlobalStyles } from '../../utils/styles';
import { bienesDocuments, bienesFuente, bienesUpdatedAt } from '../../utils/transparencia-difusion/constants';
import { useStyles } from '../../utils/transparencia-difusion/styles';

const Bienes = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title='Planeacion' />

      <div className={globalClasses.container}>
        <Header title='Padrón de bienes muebles e inmuebles' />

        <div className={globalClasses.content}>
          <Typography>Aquí puedes descargar el Padrón de Bienes Muebles e Inmuebles y Vehicular, del Instituto de Seguridad Social del Estado de Tabasco, actualizado al {bienesUpdatedAt}:</Typography>

          <List className={classes.list}>
            {bienesDocuments.map((document, index) => (
              <a key={index} href={document.file} target="_blank" rel="noopener noreferrer">
                <ListItem button>
                  <ListItemAvatar>
                    <Icon path={mdiFilePdf} size={1.7} color={escala_gris ? '#767576' : '#e54e5d'} />
                  </ListItemAvatar>
                  <ListItemText primary={document.name} secondary={document.size} />
                </ListItem>
              </a>
            ))}
          </List>
        </div>

        <UpdatedInfo fuente={bienesFuente} updatedAt={bienesUpdatedAt} />
      </div>
    </Layout>
  );
};

export default Bienes;